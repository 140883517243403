var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

/*@preserve
 * Tempus Dominus Bootstrap4 v5.1.2 (https://tempusdominus.github.io/bootstrap-4/)
 * Copyright 2016-2018 Jonathan Peterson
 * Licensed under MIT (https://github.com/tempusdominus/bootstrap-3/blob/master/LICENSE)
 */
if (typeof jQuery === "undefined") {
  throw new Error("Tempus Dominus Bootstrap4's requires jQuery. jQuery must be included before Tempus Dominus Bootstrap4's JavaScript.");
}

+function ($) {
  var version = $.fn.jquery.split(" ")[0].split(".");

  if (version[0] < 2 && version[1] < 9 || version[0] === 1 && version[1] === 9 && version[2] < 1 || version[0] >= 4) {
    throw new Error("Tempus Dominus Bootstrap4's requires at least jQuery v3.0.0 but less than v4.0.0");
  }
}(jQuery);

if (typeof moment === "undefined") {
  throw new Error("Tempus Dominus Bootstrap4's requires moment.js. Moment.js must be included before Tempus Dominus Bootstrap4's JavaScript.");
}

var version = moment.version.split(".");

if (version[0] <= 2 && version[1] < 17 || version[0] >= 3) {
  throw new Error("Tempus Dominus Bootstrap4's requires at least moment.js v2.17.0 but less than v3.0.0");
}

+function () {
  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  }();

  function _possibleConstructorReturn(self, call) {
    if (!self) {
      throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
    }

    return call && (typeof call === "object" || typeof call === "function") ? call : self;
  }

  function _inherits(subClass, superClass) {
    if (typeof superClass !== "function" && superClass !== null) {
      throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
    }

    subClass.prototype = Object.create(superClass && superClass.prototype, {
      constructor: {
        value: subClass,
        enumerable: false,
        writable: true,
        configurable: true
      }
    });
    if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
  }

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  } // ReSharper disable once InconsistentNaming


  var DateTimePicker = function ($, moment) {
    // ReSharper disable InconsistentNaming
    var NAME = "datetimepicker",
        DATA_KEY = "" + NAME,
        EVENT_KEY = "." + DATA_KEY,
        DATA_API_KEY = ".data-api",
        Selector = {
      DATA_TOGGLE: "[data-toggle=\"" + DATA_KEY + "\"]"
    },
        ClassName = {
      INPUT: NAME + "-input"
    },
        Event = {
      CHANGE: "change" + EVENT_KEY,
      BLUR: "blur" + EVENT_KEY,
      KEYUP: "keyup" + EVENT_KEY,
      KEYDOWN: "keydown" + EVENT_KEY,
      FOCUS: "focus" + EVENT_KEY,
      CLICK_DATA_API: "click" + EVENT_KEY + DATA_API_KEY,
      //emitted
      UPDATE: "update" + EVENT_KEY,
      ERROR: "error" + EVENT_KEY,
      HIDE: "hide" + EVENT_KEY,
      SHOW: "show" + EVENT_KEY
    },
        DatePickerModes = [{
      CLASS_NAME: "days",
      NAV_FUNCTION: "M",
      NAV_STEP: 1
    }, {
      CLASS_NAME: "months",
      NAV_FUNCTION: "y",
      NAV_STEP: 1
    }, {
      CLASS_NAME: "years",
      NAV_FUNCTION: "y",
      NAV_STEP: 10
    }, {
      CLASS_NAME: "decades",
      NAV_FUNCTION: "y",
      NAV_STEP: 100
    }],
        KeyMap = {
      "up": 38,
      38: "up",
      "down": 40,
      40: "down",
      "left": 37,
      37: "left",
      "right": 39,
      39: "right",
      "tab": 9,
      9: "tab",
      "escape": 27,
      27: "escape",
      "enter": 13,
      13: "enter",
      "pageUp": 33,
      33: "pageUp",
      "pageDown": 34,
      34: "pageDown",
      "shift": 16,
      16: "shift",
      "control": 17,
      17: "control",
      "space": 32,
      32: "space",
      "t": 84,
      84: "t",
      "delete": 46,
      46: "delete"
    },
        ViewModes = ["times", "days", "months", "years", "decades"],
        keyState = {},
        keyPressHandled = {};
    var Default = {
      timeZone: "",
      format: false,
      dayViewHeaderFormat: "MMMM YYYY",
      extraFormats: false,
      stepping: 1,
      minDate: false,
      maxDate: false,
      useCurrent: true,
      collapse: true,
      locale: moment.locale(),
      defaultDate: false,
      disabledDates: false,
      enabledDates: false,
      icons: {
        time: "fa fa-clock-o",
        date: "fa fa-calendar",
        up: "fa fa-arrow-up",
        down: "fa fa-arrow-down",
        previous: "fa fa-chevron-left",
        next: "fa fa-chevron-right",
        today: "fa fa-calendar-check-o",
        clear: "fa fa-delete",
        close: "fa fa-times"
      },
      tooltips: {
        today: "Go to today",
        clear: "Clear selection",
        close: "Close the picker",
        selectMonth: "Select Month",
        prevMonth: "Previous Month",
        nextMonth: "Next Month",
        selectYear: "Select Year",
        prevYear: "Previous Year",
        nextYear: "Next Year",
        selectDecade: "Select Decade",
        prevDecade: "Previous Decade",
        nextDecade: "Next Decade",
        prevCentury: "Previous Century",
        nextCentury: "Next Century",
        pickHour: "Pick Hour",
        incrementHour: "Increment Hour",
        decrementHour: "Decrement Hour",
        pickMinute: "Pick Minute",
        incrementMinute: "Increment Minute",
        decrementMinute: "Decrement Minute",
        pickSecond: "Pick Second",
        incrementSecond: "Increment Second",
        decrementSecond: "Decrement Second",
        togglePeriod: "Toggle Period",
        selectTime: "Select Time",
        selectDate: "Select Date"
      },
      useStrict: false,
      sideBySide: false,
      daysOfWeekDisabled: false,
      calendarWeeks: false,
      viewMode: "days",
      toolbarPlacement: "default",
      buttons: {
        showToday: false,
        showClear: false,
        showClose: false
      },
      widgetPositioning: {
        horizontal: "auto",
        vertical: "auto"
      },
      widgetParent: null,
      ignoreReadonly: false,
      keepOpen: false,
      focusOnShow: true,
      inline: false,
      keepInvalid: false,
      keyBinds: {
        up: function up() {
          if (!(this || _global).widget) {
            return false;
          }

          var d = (this || _global)._dates[0] || this.getMoment();

          if ((this || _global).widget.find(".datepicker").is(":visible")) {
            this.date(d.clone().subtract(7, "d"));
          } else {
            this.date(d.clone().add(this.stepping(), "m"));
          }

          return true;
        },
        down: function down() {
          if (!(this || _global).widget) {
            this.show();
            return false;
          }

          var d = (this || _global)._dates[0] || this.getMoment();

          if ((this || _global).widget.find(".datepicker").is(":visible")) {
            this.date(d.clone().add(7, "d"));
          } else {
            this.date(d.clone().subtract(this.stepping(), "m"));
          }

          return true;
        },
        "control up": function controlUp() {
          if (!(this || _global).widget) {
            return false;
          }

          var d = (this || _global)._dates[0] || this.getMoment();

          if ((this || _global).widget.find(".datepicker").is(":visible")) {
            this.date(d.clone().subtract(1, "y"));
          } else {
            this.date(d.clone().add(1, "h"));
          }

          return true;
        },
        "control down": function controlDown() {
          if (!(this || _global).widget) {
            return false;
          }

          var d = (this || _global)._dates[0] || this.getMoment();

          if ((this || _global).widget.find(".datepicker").is(":visible")) {
            this.date(d.clone().add(1, "y"));
          } else {
            this.date(d.clone().subtract(1, "h"));
          }

          return true;
        },
        left: function left() {
          if (!(this || _global).widget) {
            return false;
          }

          var d = (this || _global)._dates[0] || this.getMoment();

          if ((this || _global).widget.find(".datepicker").is(":visible")) {
            this.date(d.clone().subtract(1, "d"));
          }

          return true;
        },
        right: function right() {
          if (!(this || _global).widget) {
            return false;
          }

          var d = (this || _global)._dates[0] || this.getMoment();

          if ((this || _global).widget.find(".datepicker").is(":visible")) {
            this.date(d.clone().add(1, "d"));
          }

          return true;
        },
        pageUp: function pageUp() {
          if (!(this || _global).widget) {
            return false;
          }

          var d = (this || _global)._dates[0] || this.getMoment();

          if ((this || _global).widget.find(".datepicker").is(":visible")) {
            this.date(d.clone().subtract(1, "M"));
          }

          return true;
        },
        pageDown: function pageDown() {
          if (!(this || _global).widget) {
            return false;
          }

          var d = (this || _global)._dates[0] || this.getMoment();

          if ((this || _global).widget.find(".datepicker").is(":visible")) {
            this.date(d.clone().add(1, "M"));
          }

          return true;
        },
        enter: function enter() {
          if (!(this || _global).widget) {
            return false;
          }

          this.hide();
          return true;
        },
        escape: function escape() {
          if (!(this || _global).widget) {
            return false;
          }

          this.hide();
          return true;
        },
        "control space": function controlSpace() {
          if (!(this || _global).widget) {
            return false;
          }

          if ((this || _global).widget.find(".timepicker").is(":visible")) {
            (this || _global).widget.find(".btn[data-action=\"togglePeriod\"]").click();
          }

          return true;
        },
        t: function t() {
          if (!(this || _global).widget) {
            return false;
          }

          this.date(this.getMoment());
          return true;
        },
        "delete": function _delete() {
          if (!(this || _global).widget) {
            return false;
          }

          this.clear();
          return true;
        }
      },
      debug: false,
      allowInputToggle: false,
      disabledTimeIntervals: false,
      disabledHours: false,
      enabledHours: false,
      viewDate: false,
      allowMultidate: false,
      multidateSeparator: ","
    }; // ReSharper restore InconsistentNaming
    // ReSharper disable once DeclarationHides
    // ReSharper disable once InconsistentNaming

    var DateTimePicker = function () {
      /** @namespace eData.dateOptions */

      /** @namespace moment.tz */
      function DateTimePicker(element, options) {
        _classCallCheck(this || _global, DateTimePicker);

        (this || _global)._options = this._getOptions(options);
        (this || _global)._element = element;
        (this || _global)._dates = [];
        (this || _global)._datesFormatted = [];
        (this || _global)._viewDate = null;
        (this || _global).unset = true;
        (this || _global).component = false;
        (this || _global).widget = false;
        (this || _global).use24Hours = null;
        (this || _global).actualFormat = null;
        (this || _global).parseFormats = null;
        (this || _global).currentViewMode = null;
        (this || _global).MinViewModeNumber = 0;

        this._int();
      }
      /**
       * @return {string}
       */
      //private


      DateTimePicker.prototype._int = function _int() {
        var targetInput = (this || _global)._element.data("target-input");

        if ((this || _global)._element.is("input")) {
          (this || _global).input = (this || _global)._element;
        } else if (targetInput !== undefined) {
          if (targetInput === "nearest") {
            (this || _global).input = (this || _global)._element.find("input");
          } else {
            (this || _global).input = $(targetInput);
          }
        }

        (this || _global)._dates = [];
        (this || _global)._dates[0] = this.getMoment();
        (this || _global)._viewDate = this.getMoment().clone();
        $.extend(true, (this || _global)._options, this._dataToOptions());
        this.options((this || _global)._options);

        this._initFormatting();

        if ((this || _global).input !== undefined && (this || _global).input.is("input") && (this || _global).input.val().trim().length !== 0) {
          this._setValue(this._parseInputDate((this || _global).input.val().trim()), 0);
        } else if ((this || _global)._options.defaultDate && (this || _global).input !== undefined && (this || _global).input.attr("placeholder") === undefined) {
          this._setValue((this || _global)._options.defaultDate, 0);
        }

        if ((this || _global)._options.inline) {
          this.show();
        }
      };

      DateTimePicker.prototype._update = function _update() {
        if (!(this || _global).widget) {
          return;
        }

        this._fillDate();

        this._fillTime();
      };

      DateTimePicker.prototype._setValue = function _setValue(targetMoment, index) {
        var oldDate = (this || _global).unset ? null : (this || _global)._dates[index];
        var outpValue = ""; // case of calling setValue(null or false)

        if (!targetMoment) {
          if (!(this || _global)._options.allowMultidate || (this || _global)._dates.length === 1) {
            (this || _global).unset = true;
            (this || _global)._dates = [];
            (this || _global)._datesFormatted = [];
          } else {
            outpValue = (this || _global)._element.data("date") + ",";
            outpValue = outpValue.replace(oldDate.format((this || _global).actualFormat) + ",", "").replace(",,", "").replace(/,\s*$/, "");

            (this || _global)._dates.splice(index, 1);

            (this || _global)._datesFormatted.splice(index, 1);
          }

          if ((this || _global).input !== undefined) {
            (this || _global).input.val(outpValue);

            (this || _global).input.trigger("input");
          }

          (this || _global)._element.data("date", outpValue);

          this._notifyEvent({
            type: DateTimePicker.Event.CHANGE,
            date: false,
            oldDate: oldDate
          });

          this._update();

          return;
        }

        targetMoment = targetMoment.clone().locale((this || _global)._options.locale);

        if (this._hasTimeZone()) {
          targetMoment.tz((this || _global)._options.timeZone);
        }

        if ((this || _global)._options.stepping !== 1) {
          targetMoment.minutes(Math.round(targetMoment.minutes() / (this || _global)._options.stepping) * (this || _global)._options.stepping).seconds(0);
        }

        if (this._isValid(targetMoment)) {
          (this || _global)._dates[index] = targetMoment;
          (this || _global)._datesFormatted[index] = targetMoment.format("YYYY-MM-DD");
          (this || _global)._viewDate = targetMoment.clone();

          if ((this || _global)._options.allowMultidate && (this || _global)._dates.length > 1) {
            for (var i = 0; i < (this || _global)._dates.length; i++) {
              outpValue += "" + (this || _global)._dates[i].format((this || _global).actualFormat) + (this || _global)._options.multidateSeparator;
            }

            outpValue = outpValue.replace(/,\s*$/, "");
          } else {
            outpValue = (this || _global)._dates[index].format((this || _global).actualFormat);
          }

          if ((this || _global).input !== undefined) {
            (this || _global).input.val(outpValue);

            (this || _global).input.trigger("input");
          }

          (this || _global)._element.data("date", outpValue);

          (this || _global).unset = false;

          this._update();

          this._notifyEvent({
            type: DateTimePicker.Event.CHANGE,
            date: (this || _global)._dates[index].clone(),
            oldDate: oldDate
          });
        } else {
          if (!(this || _global)._options.keepInvalid) {
            if ((this || _global).input !== undefined) {
              (this || _global).input.val("" + ((this || _global).unset ? "" : (this || _global)._dates[index].format((this || _global).actualFormat)));

              (this || _global).input.trigger("input");
            }
          } else {
            this._notifyEvent({
              type: DateTimePicker.Event.CHANGE,
              date: targetMoment,
              oldDate: oldDate
            });
          }

          this._notifyEvent({
            type: DateTimePicker.Event.ERROR,
            date: targetMoment,
            oldDate: oldDate
          });
        }
      };

      DateTimePicker.prototype._change = function _change(e) {
        var val = $(e.target).val().trim(),
            parsedDate = val ? this._parseInputDate(val) : null;

        this._setValue(parsedDate);

        e.stopImmediatePropagation();
        return false;
      }; //noinspection JSMethodCanBeStatic


      DateTimePicker.prototype._getOptions = function _getOptions(options) {
        options = $.extend(true, {}, Default, options);
        return options;
      };

      DateTimePicker.prototype._hasTimeZone = function _hasTimeZone() {
        return moment.tz !== undefined && (this || _global)._options.timeZone !== undefined && (this || _global)._options.timeZone !== null && (this || _global)._options.timeZone !== "";
      };

      DateTimePicker.prototype._isEnabled = function _isEnabled(granularity) {
        if (typeof granularity !== "string" || granularity.length > 1) {
          throw new TypeError("isEnabled expects a single character string parameter");
        }

        switch (granularity) {
          case "y":
            return (this || _global).actualFormat.indexOf("Y") !== -1;

          case "M":
            return (this || _global).actualFormat.indexOf("M") !== -1;

          case "d":
            return (this || _global).actualFormat.toLowerCase().indexOf("d") !== -1;

          case "h":
          case "H":
            return (this || _global).actualFormat.toLowerCase().indexOf("h") !== -1;

          case "m":
            return (this || _global).actualFormat.indexOf("m") !== -1;

          case "s":
            return (this || _global).actualFormat.indexOf("s") !== -1;

          case "a":
          case "A":
            return (this || _global).actualFormat.toLowerCase().indexOf("a") !== -1;

          default:
            return false;
        }
      };

      DateTimePicker.prototype._hasTime = function _hasTime() {
        return this._isEnabled("h") || this._isEnabled("m") || this._isEnabled("s");
      };

      DateTimePicker.prototype._hasDate = function _hasDate() {
        return this._isEnabled("y") || this._isEnabled("M") || this._isEnabled("d");
      };

      DateTimePicker.prototype._dataToOptions = function _dataToOptions() {
        var eData = (this || _global)._element.data();

        var dataOptions = {};

        if (eData.dateOptions && eData.dateOptions instanceof Object) {
          dataOptions = $.extend(true, dataOptions, eData.dateOptions);
        }

        $.each((this || _global)._options, function (key) {
          var attributeName = "date" + key.charAt(0).toUpperCase() + key.slice(1); //todo data api key

          if (eData[attributeName] !== undefined) {
            dataOptions[key] = eData[attributeName];
          } else {
            delete dataOptions[key];
          }
        });
        return dataOptions;
      };

      DateTimePicker.prototype._notifyEvent = function _notifyEvent(e) {
        if (e.type === DateTimePicker.Event.CHANGE && e.date && e.date.isSame(e.oldDate) || !e.date && !e.oldDate) {
          return;
        }

        (this || _global)._element.trigger(e);
      };

      DateTimePicker.prototype._viewUpdate = function _viewUpdate(e) {
        if (e === "y") {
          e = "YYYY";
        }

        this._notifyEvent({
          type: DateTimePicker.Event.UPDATE,
          change: e,
          viewDate: (this || _global)._viewDate.clone()
        });
      };

      DateTimePicker.prototype._showMode = function _showMode(dir) {
        if (!(this || _global).widget) {
          return;
        }

        if (dir) {
          (this || _global).currentViewMode = Math.max((this || _global).MinViewModeNumber, Math.min(3, (this || _global).currentViewMode + dir));
        }

        (this || _global).widget.find(".datepicker > div").hide().filter(".datepicker-" + DatePickerModes[(this || _global).currentViewMode].CLASS_NAME).show();
      };

      DateTimePicker.prototype._isInDisabledDates = function _isInDisabledDates(testDate) {
        return (this || _global)._options.disabledDates[testDate.format("YYYY-MM-DD")] === true;
      };

      DateTimePicker.prototype._isInEnabledDates = function _isInEnabledDates(testDate) {
        return (this || _global)._options.enabledDates[testDate.format("YYYY-MM-DD")] === true;
      };

      DateTimePicker.prototype._isInDisabledHours = function _isInDisabledHours(testDate) {
        return (this || _global)._options.disabledHours[testDate.format("H")] === true;
      };

      DateTimePicker.prototype._isInEnabledHours = function _isInEnabledHours(testDate) {
        return (this || _global)._options.enabledHours[testDate.format("H")] === true;
      };

      DateTimePicker.prototype._isValid = function _isValid(targetMoment, granularity) {
        if (!targetMoment.isValid()) {
          return false;
        }

        if ((this || _global)._options.disabledDates && granularity === "d" && this._isInDisabledDates(targetMoment)) {
          return false;
        }

        if ((this || _global)._options.enabledDates && granularity === "d" && !this._isInEnabledDates(targetMoment)) {
          return false;
        }

        if ((this || _global)._options.minDate && targetMoment.isBefore((this || _global)._options.minDate, granularity)) {
          return false;
        }

        if ((this || _global)._options.maxDate && targetMoment.isAfter((this || _global)._options.maxDate, granularity)) {
          return false;
        }

        if ((this || _global)._options.daysOfWeekDisabled && granularity === "d" && (this || _global)._options.daysOfWeekDisabled.indexOf(targetMoment.day()) !== -1) {
          return false;
        }

        if ((this || _global)._options.disabledHours && (granularity === "h" || granularity === "m" || granularity === "s") && this._isInDisabledHours(targetMoment)) {
          return false;
        }

        if ((this || _global)._options.enabledHours && (granularity === "h" || granularity === "m" || granularity === "s") && !this._isInEnabledHours(targetMoment)) {
          return false;
        }

        if ((this || _global)._options.disabledTimeIntervals && (granularity === "h" || granularity === "m" || granularity === "s")) {
          var found = false;
          $.each((this || _global)._options.disabledTimeIntervals, function () {
            if (targetMoment.isBetween((this || _global)[0], (this || _global)[1])) {
              found = true;
              return false;
            }
          });

          if (found) {
            return false;
          }
        }

        return true;
      };

      DateTimePicker.prototype._parseInputDate = function _parseInputDate(inputDate) {
        if ((this || _global)._options.parseInputDate === undefined) {
          if (!moment.isMoment(inputDate)) {
            inputDate = this.getMoment(inputDate);
          }
        } else {
          inputDate = (this || _global)._options.parseInputDate(inputDate);
        } //inputDate.locale(this.options.locale);


        return inputDate;
      };

      DateTimePicker.prototype._keydown = function _keydown(e) {
        var handler = null,
            index = void 0,
            index2 = void 0,
            keyBindKeys = void 0,
            allModifiersPressed = void 0;
        var pressedKeys = [],
            pressedModifiers = {},
            currentKey = e.which,
            pressed = "p";
        keyState[currentKey] = pressed;

        for (index in keyState) {
          if (keyState.hasOwnProperty(index) && keyState[index] === pressed) {
            pressedKeys.push(index);

            if (parseInt(index, 10) !== currentKey) {
              pressedModifiers[index] = true;
            }
          }
        }

        for (index in (this || _global)._options.keyBinds) {
          if ((this || _global)._options.keyBinds.hasOwnProperty(index) && typeof (this || _global)._options.keyBinds[index] === "function") {
            keyBindKeys = index.split(" ");

            if (keyBindKeys.length === pressedKeys.length && KeyMap[currentKey] === keyBindKeys[keyBindKeys.length - 1]) {
              allModifiersPressed = true;

              for (index2 = keyBindKeys.length - 2; index2 >= 0; index2--) {
                if (!(KeyMap[keyBindKeys[index2]] in pressedModifiers)) {
                  allModifiersPressed = false;
                  break;
                }
              }

              if (allModifiersPressed) {
                handler = (this || _global)._options.keyBinds[index];
                break;
              }
            }
          }
        }

        if (handler) {
          if (handler.call(this || _global)) {
            e.stopPropagation();
            e.preventDefault();
          }
        }
      }; //noinspection JSMethodCanBeStatic,SpellCheckingInspection


      DateTimePicker.prototype._keyup = function _keyup(e) {
        keyState[e.which] = "r";

        if (keyPressHandled[e.which]) {
          keyPressHandled[e.which] = false;
          e.stopPropagation();
          e.preventDefault();
        }
      };

      DateTimePicker.prototype._indexGivenDates = function _indexGivenDates(givenDatesArray) {
        // Store given enabledDates and disabledDates as keys.
        // This way we can check their existence in O(1) time instead of looping through whole array.
        // (for example: options.enabledDates['2014-02-27'] === true)
        var givenDatesIndexed = {},
            self = this || _global;
        $.each(givenDatesArray, function () {
          var dDate = self._parseInputDate(this || _global);

          if (dDate.isValid()) {
            givenDatesIndexed[dDate.format("YYYY-MM-DD")] = true;
          }
        });
        return Object.keys(givenDatesIndexed).length ? givenDatesIndexed : false;
      };

      DateTimePicker.prototype._indexGivenHours = function _indexGivenHours(givenHoursArray) {
        // Store given enabledHours and disabledHours as keys.
        // This way we can check their existence in O(1) time instead of looping through whole array.
        // (for example: options.enabledHours['2014-02-27'] === true)
        var givenHoursIndexed = {};
        $.each(givenHoursArray, function () {
          givenHoursIndexed[this || _global] = true;
        });
        return Object.keys(givenHoursIndexed).length ? givenHoursIndexed : false;
      };

      DateTimePicker.prototype._initFormatting = function _initFormatting() {
        var format = (this || _global)._options.format || "L LT",
            self = this || _global;
        (this || _global).actualFormat = format.replace(/(\[[^\[]*])|(\\)?(LTS|LT|LL?L?L?|l{1,4})/g, function (formatInput) {
          return self._dates[0].localeData().longDateFormat(formatInput) || formatInput; //todo taking the first date should be ok
        });
        (this || _global).parseFormats = (this || _global)._options.extraFormats ? (this || _global)._options.extraFormats.slice() : [];

        if ((this || _global).parseFormats.indexOf(format) < 0 && (this || _global).parseFormats.indexOf((this || _global).actualFormat) < 0) {
          (this || _global).parseFormats.push((this || _global).actualFormat);
        }

        (this || _global).use24Hours = (this || _global).actualFormat.toLowerCase().indexOf("a") < 1 && (this || _global).actualFormat.replace(/\[.*?]/g, "").indexOf("h") < 1;

        if (this._isEnabled("y")) {
          (this || _global).MinViewModeNumber = 2;
        }

        if (this._isEnabled("M")) {
          (this || _global).MinViewModeNumber = 1;
        }

        if (this._isEnabled("d")) {
          (this || _global).MinViewModeNumber = 0;
        }

        (this || _global).currentViewMode = Math.max((this || _global).MinViewModeNumber, (this || _global).currentViewMode);

        if (!(this || _global).unset) {
          this._setValue((this || _global)._dates[0], 0);
        }
      };

      DateTimePicker.prototype._getLastPickedDate = function _getLastPickedDate() {
        return (this || _global)._dates[this._getLastPickedDateIndex()];
      };

      DateTimePicker.prototype._getLastPickedDateIndex = function _getLastPickedDateIndex() {
        return (this || _global)._dates.length - 1;
      }; //public


      DateTimePicker.prototype.getMoment = function getMoment(d) {
        var returnMoment = void 0;

        if (d === undefined || d === null) {
          returnMoment = moment(); //TODO should this use format? and locale?
        } else if (this._hasTimeZone()) {
          // There is a string to parse and a default time zone
          // parse with the tz function which takes a default time zone if it is not in the format string
          returnMoment = moment.tz(d, (this || _global).parseFormats, (this || _global)._options.locale, (this || _global)._options.useStrict, (this || _global)._options.timeZone);
        } else {
          returnMoment = moment(d, (this || _global).parseFormats, (this || _global)._options.locale, (this || _global)._options.useStrict);
        }

        if (this._hasTimeZone()) {
          returnMoment.tz((this || _global)._options.timeZone);
        }

        return returnMoment;
      };

      DateTimePicker.prototype.toggle = function toggle() {
        return (this || _global).widget ? this.hide() : this.show();
      };

      DateTimePicker.prototype.ignoreReadonly = function ignoreReadonly(_ignoreReadonly) {
        if (arguments.length === 0) {
          return (this || _global)._options.ignoreReadonly;
        }

        if (typeof _ignoreReadonly !== "boolean") {
          throw new TypeError("ignoreReadonly () expects a boolean parameter");
        }

        (this || _global)._options.ignoreReadonly = _ignoreReadonly;
      };

      DateTimePicker.prototype.options = function options(newOptions) {
        if (arguments.length === 0) {
          return $.extend(true, {}, (this || _global)._options);
        }

        if (!(newOptions instanceof Object)) {
          throw new TypeError("options() this.options parameter should be an object");
        }

        $.extend(true, (this || _global)._options, newOptions);
        var self = this || _global;
        $.each((this || _global)._options, function (key, value) {
          if (self[key] !== undefined) {
            self[key](value);
          }
        });
      };

      DateTimePicker.prototype.date = function date(newDate, index) {
        index = index || 0;

        if (arguments.length === 0) {
          if ((this || _global).unset) {
            return null;
          }

          if ((this || _global)._options.allowMultidate) {
            return (this || _global)._dates.join((this || _global)._options.multidateSeparator);
          } else {
            return (this || _global)._dates[index].clone();
          }
        }

        if (newDate !== null && typeof newDate !== "string" && !moment.isMoment(newDate) && !(newDate instanceof Date)) {
          throw new TypeError("date() parameter must be one of [null, string, moment or Date]");
        }

        this._setValue(newDate === null ? null : this._parseInputDate(newDate), index);
      };

      DateTimePicker.prototype.format = function format(newFormat) {
        if (arguments.length === 0) {
          return (this || _global)._options.format;
        }

        if (typeof newFormat !== "string" && (typeof newFormat !== "boolean" || newFormat !== false)) {
          throw new TypeError("format() expects a string or boolean:false parameter " + newFormat);
        }

        (this || _global)._options.format = newFormat;

        if ((this || _global).actualFormat) {
          this._initFormatting(); // reinitialize formatting

        }
      };

      DateTimePicker.prototype.timeZone = function timeZone(newZone) {
        if (arguments.length === 0) {
          return (this || _global)._options.timeZone;
        }

        if (typeof newZone !== "string") {
          throw new TypeError("newZone() expects a string parameter");
        }

        (this || _global)._options.timeZone = newZone;
      };

      DateTimePicker.prototype.dayViewHeaderFormat = function dayViewHeaderFormat(newFormat) {
        if (arguments.length === 0) {
          return (this || _global)._options.dayViewHeaderFormat;
        }

        if (typeof newFormat !== "string") {
          throw new TypeError("dayViewHeaderFormat() expects a string parameter");
        }

        (this || _global)._options.dayViewHeaderFormat = newFormat;
      };

      DateTimePicker.prototype.extraFormats = function extraFormats(formats) {
        if (arguments.length === 0) {
          return (this || _global)._options.extraFormats;
        }

        if (formats !== false && !(formats instanceof Array)) {
          throw new TypeError("extraFormats() expects an array or false parameter");
        }

        (this || _global)._options.extraFormats = formats;

        if ((this || _global).parseFormats) {
          this._initFormatting(); // reinit formatting

        }
      };

      DateTimePicker.prototype.disabledDates = function disabledDates(dates) {
        if (arguments.length === 0) {
          return (this || _global)._options.disabledDates ? $.extend({}, (this || _global)._options.disabledDates) : (this || _global)._options.disabledDates;
        }

        if (!dates) {
          (this || _global)._options.disabledDates = false;

          this._update();

          return true;
        }

        if (!(dates instanceof Array)) {
          throw new TypeError("disabledDates() expects an array parameter");
        }

        (this || _global)._options.disabledDates = this._indexGivenDates(dates);
        (this || _global)._options.enabledDates = false;

        this._update();
      };

      DateTimePicker.prototype.enabledDates = function enabledDates(dates) {
        if (arguments.length === 0) {
          return (this || _global)._options.enabledDates ? $.extend({}, (this || _global)._options.enabledDates) : (this || _global)._options.enabledDates;
        }

        if (!dates) {
          (this || _global)._options.enabledDates = false;

          this._update();

          return true;
        }

        if (!(dates instanceof Array)) {
          throw new TypeError("enabledDates() expects an array parameter");
        }

        (this || _global)._options.enabledDates = this._indexGivenDates(dates);
        (this || _global)._options.disabledDates = false;

        this._update();
      };

      DateTimePicker.prototype.daysOfWeekDisabled = function daysOfWeekDisabled(_daysOfWeekDisabled) {
        if (arguments.length === 0) {
          return (this || _global)._options.daysOfWeekDisabled.splice(0);
        }

        if (typeof _daysOfWeekDisabled === "boolean" && !_daysOfWeekDisabled) {
          (this || _global)._options.daysOfWeekDisabled = false;

          this._update();

          return true;
        }

        if (!(_daysOfWeekDisabled instanceof Array)) {
          throw new TypeError("daysOfWeekDisabled() expects an array parameter");
        }

        (this || _global)._options.daysOfWeekDisabled = _daysOfWeekDisabled.reduce(function (previousValue, currentValue) {
          currentValue = parseInt(currentValue, 10);

          if (currentValue > 6 || currentValue < 0 || isNaN(currentValue)) {
            return previousValue;
          }

          if (previousValue.indexOf(currentValue) === -1) {
            previousValue.push(currentValue);
          }

          return previousValue;
        }, []).sort();

        if ((this || _global)._options.useCurrent && !(this || _global)._options.keepInvalid) {
          for (var i = 0; i < (this || _global)._dates.length; i++) {
            var tries = 0;

            while (!this._isValid((this || _global)._dates[i], "d")) {
              (this || _global)._dates[i].add(1, "d");

              if (tries === 31) {
                throw "Tried 31 times to find a valid date";
              }

              tries++;
            }

            this._setValue((this || _global)._dates[i], i);
          }
        }

        this._update();
      };

      DateTimePicker.prototype.maxDate = function maxDate(_maxDate) {
        if (arguments.length === 0) {
          return (this || _global)._options.maxDate ? (this || _global)._options.maxDate.clone() : (this || _global)._options.maxDate;
        }

        if (typeof _maxDate === "boolean" && _maxDate === false) {
          (this || _global)._options.maxDate = false;

          this._update();

          return true;
        }

        if (typeof _maxDate === "string") {
          if (_maxDate === "now" || _maxDate === "moment") {
            _maxDate = this.getMoment();
          }
        }

        var parsedDate = this._parseInputDate(_maxDate);

        if (!parsedDate.isValid()) {
          throw new TypeError("maxDate() Could not parse date parameter: " + _maxDate);
        }

        if ((this || _global)._options.minDate && parsedDate.isBefore((this || _global)._options.minDate)) {
          throw new TypeError("maxDate() date parameter is before this.options.minDate: " + parsedDate.format((this || _global).actualFormat));
        }

        (this || _global)._options.maxDate = parsedDate;

        for (var i = 0; i < (this || _global)._dates.length; i++) {
          if ((this || _global)._options.useCurrent && !(this || _global)._options.keepInvalid && (this || _global)._dates[i].isAfter(_maxDate)) {
            this._setValue((this || _global)._options.maxDate, i);
          }
        }

        if ((this || _global)._viewDate.isAfter(parsedDate)) {
          (this || _global)._viewDate = parsedDate.clone().subtract((this || _global)._options.stepping, "m");
        }

        this._update();
      };

      DateTimePicker.prototype.minDate = function minDate(_minDate) {
        if (arguments.length === 0) {
          return (this || _global)._options.minDate ? (this || _global)._options.minDate.clone() : (this || _global)._options.minDate;
        }

        if (typeof _minDate === "boolean" && _minDate === false) {
          (this || _global)._options.minDate = false;

          this._update();

          return true;
        }

        if (typeof _minDate === "string") {
          if (_minDate === "now" || _minDate === "moment") {
            _minDate = this.getMoment();
          }
        }

        var parsedDate = this._parseInputDate(_minDate);

        if (!parsedDate.isValid()) {
          throw new TypeError("minDate() Could not parse date parameter: " + _minDate);
        }

        if ((this || _global)._options.maxDate && parsedDate.isAfter((this || _global)._options.maxDate)) {
          throw new TypeError("minDate() date parameter is after this.options.maxDate: " + parsedDate.format((this || _global).actualFormat));
        }

        (this || _global)._options.minDate = parsedDate;

        for (var i = 0; i < (this || _global)._dates.length; i++) {
          if ((this || _global)._options.useCurrent && !(this || _global)._options.keepInvalid && (this || _global)._dates[i].isBefore(_minDate)) {
            this._setValue((this || _global)._options.minDate, i);
          }
        }

        if ((this || _global)._viewDate.isBefore(parsedDate)) {
          (this || _global)._viewDate = parsedDate.clone().add((this || _global)._options.stepping, "m");
        }

        this._update();
      };

      DateTimePicker.prototype.defaultDate = function defaultDate(_defaultDate) {
        if (arguments.length === 0) {
          return (this || _global)._options.defaultDate ? (this || _global)._options.defaultDate.clone() : (this || _global)._options.defaultDate;
        }

        if (!_defaultDate) {
          (this || _global)._options.defaultDate = false;
          return true;
        }

        if (typeof _defaultDate === "string") {
          if (_defaultDate === "now" || _defaultDate === "moment") {
            _defaultDate = this.getMoment();
          } else {
            _defaultDate = this.getMoment(_defaultDate);
          }
        }

        var parsedDate = this._parseInputDate(_defaultDate);

        if (!parsedDate.isValid()) {
          throw new TypeError("defaultDate() Could not parse date parameter: " + _defaultDate);
        }

        if (!this._isValid(parsedDate)) {
          throw new TypeError("defaultDate() date passed is invalid according to component setup validations");
        }

        (this || _global)._options.defaultDate = parsedDate;

        if ((this || _global)._options.defaultDate && (this || _global)._options.inline || (this || _global).input !== undefined && (this || _global).input.val().trim() === "") {
          this._setValue((this || _global)._options.defaultDate, 0);
        }
      };

      DateTimePicker.prototype.locale = function locale(_locale) {
        if (arguments.length === 0) {
          return (this || _global)._options.locale;
        }

        if (!moment.localeData(_locale)) {
          throw new TypeError("locale() locale " + _locale + " is not loaded from moment locales!");
        }

        (this || _global)._options.locale = _locale;

        for (var i = 0; i < (this || _global)._dates.length; i++) {
          (this || _global)._dates[i].locale((this || _global)._options.locale);
        }

        (this || _global)._viewDate.locale((this || _global)._options.locale);

        if ((this || _global).actualFormat) {
          this._initFormatting(); // reinitialize formatting

        }

        if ((this || _global).widget) {
          this.hide();
          this.show();
        }
      };

      DateTimePicker.prototype.stepping = function stepping(_stepping) {
        if (arguments.length === 0) {
          return (this || _global)._options.stepping;
        }

        _stepping = parseInt(_stepping, 10);

        if (isNaN(_stepping) || _stepping < 1) {
          _stepping = 1;
        }

        (this || _global)._options.stepping = _stepping;
      };

      DateTimePicker.prototype.useCurrent = function useCurrent(_useCurrent) {
        var useCurrentOptions = ["year", "month", "day", "hour", "minute"];

        if (arguments.length === 0) {
          return (this || _global)._options.useCurrent;
        }

        if (typeof _useCurrent !== "boolean" && typeof _useCurrent !== "string") {
          throw new TypeError("useCurrent() expects a boolean or string parameter");
        }

        if (typeof _useCurrent === "string" && useCurrentOptions.indexOf(_useCurrent.toLowerCase()) === -1) {
          throw new TypeError("useCurrent() expects a string parameter of " + useCurrentOptions.join(", "));
        }

        (this || _global)._options.useCurrent = _useCurrent;
      };

      DateTimePicker.prototype.collapse = function collapse(_collapse) {
        if (arguments.length === 0) {
          return (this || _global)._options.collapse;
        }

        if (typeof _collapse !== "boolean") {
          throw new TypeError("collapse() expects a boolean parameter");
        }

        if ((this || _global)._options.collapse === _collapse) {
          return true;
        }

        (this || _global)._options.collapse = _collapse;

        if ((this || _global).widget) {
          this.hide();
          this.show();
        }
      };

      DateTimePicker.prototype.icons = function icons(_icons) {
        if (arguments.length === 0) {
          return $.extend({}, (this || _global)._options.icons);
        }

        if (!(_icons instanceof Object)) {
          throw new TypeError("icons() expects parameter to be an Object");
        }

        $.extend((this || _global)._options.icons, _icons);

        if ((this || _global).widget) {
          this.hide();
          this.show();
        }
      };

      DateTimePicker.prototype.tooltips = function tooltips(_tooltips) {
        if (arguments.length === 0) {
          return $.extend({}, (this || _global)._options.tooltips);
        }

        if (!(_tooltips instanceof Object)) {
          throw new TypeError("tooltips() expects parameter to be an Object");
        }

        $.extend((this || _global)._options.tooltips, _tooltips);

        if ((this || _global).widget) {
          this.hide();
          this.show();
        }
      };

      DateTimePicker.prototype.useStrict = function useStrict(_useStrict) {
        if (arguments.length === 0) {
          return (this || _global)._options.useStrict;
        }

        if (typeof _useStrict !== "boolean") {
          throw new TypeError("useStrict() expects a boolean parameter");
        }

        (this || _global)._options.useStrict = _useStrict;
      };

      DateTimePicker.prototype.sideBySide = function sideBySide(_sideBySide) {
        if (arguments.length === 0) {
          return (this || _global)._options.sideBySide;
        }

        if (typeof _sideBySide !== "boolean") {
          throw new TypeError("sideBySide() expects a boolean parameter");
        }

        (this || _global)._options.sideBySide = _sideBySide;

        if ((this || _global).widget) {
          this.hide();
          this.show();
        }
      };

      DateTimePicker.prototype.viewMode = function viewMode(_viewMode) {
        if (arguments.length === 0) {
          return (this || _global)._options.viewMode;
        }

        if (typeof _viewMode !== "string") {
          throw new TypeError("viewMode() expects a string parameter");
        }

        if (DateTimePicker.ViewModes.indexOf(_viewMode) === -1) {
          throw new TypeError("viewMode() parameter must be one of (" + DateTimePicker.ViewModes.join(", ") + ") value");
        }

        (this || _global)._options.viewMode = _viewMode;
        (this || _global).currentViewMode = Math.max(DateTimePicker.ViewModes.indexOf(_viewMode) - 1, (this || _global).MinViewModeNumber);

        this._showMode();
      };

      DateTimePicker.prototype.calendarWeeks = function calendarWeeks(_calendarWeeks) {
        if (arguments.length === 0) {
          return (this || _global)._options.calendarWeeks;
        }

        if (typeof _calendarWeeks !== "boolean") {
          throw new TypeError("calendarWeeks() expects parameter to be a boolean value");
        }

        (this || _global)._options.calendarWeeks = _calendarWeeks;

        this._update();
      };

      DateTimePicker.prototype.buttons = function buttons(_buttons) {
        if (arguments.length === 0) {
          return $.extend({}, (this || _global)._options.buttons);
        }

        if (!(_buttons instanceof Object)) {
          throw new TypeError("buttons() expects parameter to be an Object");
        }

        $.extend((this || _global)._options.buttons, _buttons);

        if (typeof (this || _global)._options.buttons.showToday !== "boolean") {
          throw new TypeError("buttons.showToday expects a boolean parameter");
        }

        if (typeof (this || _global)._options.buttons.showClear !== "boolean") {
          throw new TypeError("buttons.showClear expects a boolean parameter");
        }

        if (typeof (this || _global)._options.buttons.showClose !== "boolean") {
          throw new TypeError("buttons.showClose expects a boolean parameter");
        }

        if ((this || _global).widget) {
          this.hide();
          this.show();
        }
      };

      DateTimePicker.prototype.keepOpen = function keepOpen(_keepOpen) {
        if (arguments.length === 0) {
          return (this || _global)._options.keepOpen;
        }

        if (typeof _keepOpen !== "boolean") {
          throw new TypeError("keepOpen() expects a boolean parameter");
        }

        (this || _global)._options.keepOpen = _keepOpen;
      };

      DateTimePicker.prototype.focusOnShow = function focusOnShow(_focusOnShow) {
        if (arguments.length === 0) {
          return (this || _global)._options.focusOnShow;
        }

        if (typeof _focusOnShow !== "boolean") {
          throw new TypeError("focusOnShow() expects a boolean parameter");
        }

        (this || _global)._options.focusOnShow = _focusOnShow;
      };

      DateTimePicker.prototype.inline = function inline(_inline) {
        if (arguments.length === 0) {
          return (this || _global)._options.inline;
        }

        if (typeof _inline !== "boolean") {
          throw new TypeError("inline() expects a boolean parameter");
        }

        (this || _global)._options.inline = _inline;
      };

      DateTimePicker.prototype.clear = function clear() {
        this._setValue(null); //todo

      };

      DateTimePicker.prototype.keyBinds = function keyBinds(_keyBinds) {
        if (arguments.length === 0) {
          return (this || _global)._options.keyBinds;
        }

        (this || _global)._options.keyBinds = _keyBinds;
      };

      DateTimePicker.prototype.debug = function debug(_debug) {
        if (typeof _debug !== "boolean") {
          throw new TypeError("debug() expects a boolean parameter");
        }

        (this || _global)._options.debug = _debug;
      };

      DateTimePicker.prototype.allowInputToggle = function allowInputToggle(_allowInputToggle) {
        if (arguments.length === 0) {
          return (this || _global)._options.allowInputToggle;
        }

        if (typeof _allowInputToggle !== "boolean") {
          throw new TypeError("allowInputToggle() expects a boolean parameter");
        }

        (this || _global)._options.allowInputToggle = _allowInputToggle;
      };

      DateTimePicker.prototype.keepInvalid = function keepInvalid(_keepInvalid) {
        if (arguments.length === 0) {
          return (this || _global)._options.keepInvalid;
        }

        if (typeof _keepInvalid !== "boolean") {
          throw new TypeError("keepInvalid() expects a boolean parameter");
        }

        (this || _global)._options.keepInvalid = _keepInvalid;
      };

      DateTimePicker.prototype.datepickerInput = function datepickerInput(_datepickerInput) {
        if (arguments.length === 0) {
          return (this || _global)._options.datepickerInput;
        }

        if (typeof _datepickerInput !== "string") {
          throw new TypeError("datepickerInput() expects a string parameter");
        }

        (this || _global)._options.datepickerInput = _datepickerInput;
      };

      DateTimePicker.prototype.parseInputDate = function parseInputDate(_parseInputDate2) {
        if (arguments.length === 0) {
          return (this || _global)._options.parseInputDate;
        }

        if (typeof _parseInputDate2 !== "function") {
          throw new TypeError("parseInputDate() should be as function");
        }

        (this || _global)._options.parseInputDate = _parseInputDate2;
      };

      DateTimePicker.prototype.disabledTimeIntervals = function disabledTimeIntervals(_disabledTimeIntervals) {
        if (arguments.length === 0) {
          return (this || _global)._options.disabledTimeIntervals ? $.extend({}, (this || _global)._options.disabledTimeIntervals) : (this || _global)._options.disabledTimeIntervals;
        }

        if (!_disabledTimeIntervals) {
          (this || _global)._options.disabledTimeIntervals = false;

          this._update();

          return true;
        }

        if (!(_disabledTimeIntervals instanceof Array)) {
          throw new TypeError("disabledTimeIntervals() expects an array parameter");
        }

        (this || _global)._options.disabledTimeIntervals = _disabledTimeIntervals;

        this._update();
      };

      DateTimePicker.prototype.disabledHours = function disabledHours(hours) {
        if (arguments.length === 0) {
          return (this || _global)._options.disabledHours ? $.extend({}, (this || _global)._options.disabledHours) : (this || _global)._options.disabledHours;
        }

        if (!hours) {
          (this || _global)._options.disabledHours = false;

          this._update();

          return true;
        }

        if (!(hours instanceof Array)) {
          throw new TypeError("disabledHours() expects an array parameter");
        }

        (this || _global)._options.disabledHours = this._indexGivenHours(hours);
        (this || _global)._options.enabledHours = false;

        if ((this || _global)._options.useCurrent && !(this || _global)._options.keepInvalid) {
          for (var i = 0; i < (this || _global)._dates.length; i++) {
            var tries = 0;

            while (!this._isValid((this || _global)._dates[i], "h")) {
              (this || _global)._dates[i].add(1, "h");

              if (tries === 24) {
                throw "Tried 24 times to find a valid date";
              }

              tries++;
            }

            this._setValue((this || _global)._dates[i], i);
          }
        }

        this._update();
      };

      DateTimePicker.prototype.enabledHours = function enabledHours(hours) {
        if (arguments.length === 0) {
          return (this || _global)._options.enabledHours ? $.extend({}, (this || _global)._options.enabledHours) : (this || _global)._options.enabledHours;
        }

        if (!hours) {
          (this || _global)._options.enabledHours = false;

          this._update();

          return true;
        }

        if (!(hours instanceof Array)) {
          throw new TypeError("enabledHours() expects an array parameter");
        }

        (this || _global)._options.enabledHours = this._indexGivenHours(hours);
        (this || _global)._options.disabledHours = false;

        if ((this || _global)._options.useCurrent && !(this || _global)._options.keepInvalid) {
          for (var i = 0; i < (this || _global)._dates.length; i++) {
            var tries = 0;

            while (!this._isValid((this || _global)._dates[i], "h")) {
              (this || _global)._dates[i].add(1, "h");

              if (tries === 24) {
                throw "Tried 24 times to find a valid date";
              }

              tries++;
            }

            this._setValue((this || _global)._dates[i], i);
          }
        }

        this._update();
      };

      DateTimePicker.prototype.viewDate = function viewDate(newDate) {
        if (arguments.length === 0) {
          return (this || _global)._viewDate.clone();
        }

        if (!newDate) {
          (this || _global)._viewDate = ((this || _global)._dates[0] || this.getMoment()).clone();
          return true;
        }

        if (typeof newDate !== "string" && !moment.isMoment(newDate) && !(newDate instanceof Date)) {
          throw new TypeError("viewDate() parameter must be one of [string, moment or Date]");
        }

        (this || _global)._viewDate = this._parseInputDate(newDate);

        this._viewUpdate();
      };

      DateTimePicker.prototype.allowMultidate = function allowMultidate(_allowMultidate) {
        if (typeof _allowMultidate !== "boolean") {
          throw new TypeError("allowMultidate() expects a boolean parameter");
        }

        (this || _global)._options.allowMultidate = _allowMultidate;
      };

      DateTimePicker.prototype.multidateSeparator = function multidateSeparator(_multidateSeparator) {
        if (arguments.length === 0) {
          return (this || _global)._options.multidateSeparator;
        }

        if (typeof _multidateSeparator !== "string" || _multidateSeparator.length > 1) {
          throw new TypeError("multidateSeparator expects a single character string parameter");
        }

        (this || _global)._options.multidateSeparator = _multidateSeparator;
      };

      _createClass(DateTimePicker, null, [{
        key: "NAME",
        get: function get() {
          return NAME;
        }
        /**
         * @return {string}
         */

      }, {
        key: "DATA_KEY",
        get: function get() {
          return DATA_KEY;
        }
        /**
         * @return {string}
         */

      }, {
        key: "EVENT_KEY",
        get: function get() {
          return EVENT_KEY;
        }
        /**
         * @return {string}
         */

      }, {
        key: "DATA_API_KEY",
        get: function get() {
          return DATA_API_KEY;
        }
      }, {
        key: "DatePickerModes",
        get: function get() {
          return DatePickerModes;
        }
      }, {
        key: "ViewModes",
        get: function get() {
          return ViewModes;
        }
      }, {
        key: "Event",
        get: function get() {
          return Event;
        }
      }, {
        key: "Selector",
        get: function get() {
          return Selector;
        }
      }, {
        key: "Default",
        get: function get() {
          return Default;
        },
        set: function set(value) {
          Default = value;
        }
      }, {
        key: "ClassName",
        get: function get() {
          return ClassName;
        }
      }]);

      return DateTimePicker;
    }();

    return DateTimePicker;
  }(jQuery, moment); //noinspection JSUnusedGlobalSymbols

  /* global DateTimePicker */


  var TempusDominusBootstrap4 = function ($) {
    // eslint-disable-line no-unused-vars
    // ReSharper disable once InconsistentNaming
    var JQUERY_NO_CONFLICT = $.fn[DateTimePicker.NAME],
        verticalModes = ["top", "bottom", "auto"],
        horizontalModes = ["left", "right", "auto"],
        toolbarPlacements = ["default", "top", "bottom"],
        getSelectorFromElement = function getSelectorFromElement($element) {
      var selector = $element.data("target"),
          $selector = void 0;

      if (!selector) {
        selector = $element.attr("href") || "";
        selector = /^#[a-z]/i.test(selector) ? selector : null;
      }

      $selector = $(selector);

      if ($selector.length === 0) {
        return $selector;
      }

      if (!$selector.data(DateTimePicker.DATA_KEY)) {
        $.extend({}, $selector.data(), $(this || _global).data());
      }

      return $selector;
    }; // ReSharper disable once InconsistentNaming


    var TempusDominusBootstrap4 = function (_DateTimePicker) {
      _inherits(TempusDominusBootstrap4, _DateTimePicker);

      function TempusDominusBootstrap4(element, options) {
        _classCallCheck(this || _global, TempusDominusBootstrap4);

        var _this = _possibleConstructorReturn(this || _global, _DateTimePicker.call(this || _global, element, options));

        _this._init();

        return _this;
      }

      TempusDominusBootstrap4.prototype._init = function _init() {
        if ((this || _global)._element.hasClass("input-group")) {
          var datepickerButton = (this || _global)._element.find(".datepickerbutton");

          if (datepickerButton.length === 0) {
            (this || _global).component = (this || _global)._element.find("[data-toggle=\"datetimepicker\"]");
          } else {
            (this || _global).component = datepickerButton;
          }
        }
      };

      TempusDominusBootstrap4.prototype._getDatePickerTemplate = function _getDatePickerTemplate() {
        var headTemplate = $("<thead>").append($("<tr>").append($("<th>").addClass("prev").attr("data-action", "previous").append($("<span>").addClass((this || _global)._options.icons.previous))).append($("<th>").addClass("picker-switch").attr("data-action", "pickerSwitch").attr("colspan", "" + ((this || _global)._options.calendarWeeks ? "6" : "5"))).append($("<th>").addClass("next").attr("data-action", "next").append($("<span>").addClass((this || _global)._options.icons.next)))),
            contTemplate = $("<tbody>").append($("<tr>").append($("<td>").attr("colspan", "" + ((this || _global)._options.calendarWeeks ? "8" : "7"))));
        return [$("<div>").addClass("datepicker-days").append($("<table>").addClass("table table-sm").append(headTemplate).append($("<tbody>"))), $("<div>").addClass("datepicker-months").append($("<table>").addClass("table-condensed").append(headTemplate.clone()).append(contTemplate.clone())), $("<div>").addClass("datepicker-years").append($("<table>").addClass("table-condensed").append(headTemplate.clone()).append(contTemplate.clone())), $("<div>").addClass("datepicker-decades").append($("<table>").addClass("table-condensed").append(headTemplate.clone()).append(contTemplate.clone()))];
      };

      TempusDominusBootstrap4.prototype._getTimePickerMainTemplate = function _getTimePickerMainTemplate() {
        var topRow = $("<tr>"),
            middleRow = $("<tr>"),
            bottomRow = $("<tr>");

        if (this._isEnabled("h")) {
          topRow.append($("<td>").append($("<a>").attr({
            href: "#",
            tabindex: "-1",
            "title": (this || _global)._options.tooltips.incrementHour
          }).addClass("btn").attr("data-action", "incrementHours").append($("<span>").addClass((this || _global)._options.icons.up))));
          middleRow.append($("<td>").append($("<span>").addClass("timepicker-hour").attr({
            "data-time-component": "hours",
            "title": (this || _global)._options.tooltips.pickHour
          }).attr("data-action", "showHours")));
          bottomRow.append($("<td>").append($("<a>").attr({
            href: "#",
            tabindex: "-1",
            "title": (this || _global)._options.tooltips.decrementHour
          }).addClass("btn").attr("data-action", "decrementHours").append($("<span>").addClass((this || _global)._options.icons.down))));
        }

        if (this._isEnabled("m")) {
          if (this._isEnabled("h")) {
            topRow.append($("<td>").addClass("separator"));
            middleRow.append($("<td>").addClass("separator").html(":"));
            bottomRow.append($("<td>").addClass("separator"));
          }

          topRow.append($("<td>").append($("<a>").attr({
            href: "#",
            tabindex: "-1",
            "title": (this || _global)._options.tooltips.incrementMinute
          }).addClass("btn").attr("data-action", "incrementMinutes").append($("<span>").addClass((this || _global)._options.icons.up))));
          middleRow.append($("<td>").append($("<span>").addClass("timepicker-minute").attr({
            "data-time-component": "minutes",
            "title": (this || _global)._options.tooltips.pickMinute
          }).attr("data-action", "showMinutes")));
          bottomRow.append($("<td>").append($("<a>").attr({
            href: "#",
            tabindex: "-1",
            "title": (this || _global)._options.tooltips.decrementMinute
          }).addClass("btn").attr("data-action", "decrementMinutes").append($("<span>").addClass((this || _global)._options.icons.down))));
        }

        if (this._isEnabled("s")) {
          if (this._isEnabled("m")) {
            topRow.append($("<td>").addClass("separator"));
            middleRow.append($("<td>").addClass("separator").html(":"));
            bottomRow.append($("<td>").addClass("separator"));
          }

          topRow.append($("<td>").append($("<a>").attr({
            href: "#",
            tabindex: "-1",
            "title": (this || _global)._options.tooltips.incrementSecond
          }).addClass("btn").attr("data-action", "incrementSeconds").append($("<span>").addClass((this || _global)._options.icons.up))));
          middleRow.append($("<td>").append($("<span>").addClass("timepicker-second").attr({
            "data-time-component": "seconds",
            "title": (this || _global)._options.tooltips.pickSecond
          }).attr("data-action", "showSeconds")));
          bottomRow.append($("<td>").append($("<a>").attr({
            href: "#",
            tabindex: "-1",
            "title": (this || _global)._options.tooltips.decrementSecond
          }).addClass("btn").attr("data-action", "decrementSeconds").append($("<span>").addClass((this || _global)._options.icons.down))));
        }

        if (!(this || _global).use24Hours) {
          topRow.append($("<td>").addClass("separator"));
          middleRow.append($("<td>").append($("<button>").addClass("btn btn-primary").attr({
            "data-action": "togglePeriod",
            tabindex: "-1",
            "title": (this || _global)._options.tooltips.togglePeriod
          })));
          bottomRow.append($("<td>").addClass("separator"));
        }

        return $("<div>").addClass("timepicker-picker").append($("<table>").addClass("table-condensed").append([topRow, middleRow, bottomRow]));
      };

      TempusDominusBootstrap4.prototype._getTimePickerTemplate = function _getTimePickerTemplate() {
        var hoursView = $("<div>").addClass("timepicker-hours").append($("<table>").addClass("table-condensed")),
            minutesView = $("<div>").addClass("timepicker-minutes").append($("<table>").addClass("table-condensed")),
            secondsView = $("<div>").addClass("timepicker-seconds").append($("<table>").addClass("table-condensed")),
            ret = [this._getTimePickerMainTemplate()];

        if (this._isEnabled("h")) {
          ret.push(hoursView);
        }

        if (this._isEnabled("m")) {
          ret.push(minutesView);
        }

        if (this._isEnabled("s")) {
          ret.push(secondsView);
        }

        return ret;
      };

      TempusDominusBootstrap4.prototype._getToolbar = function _getToolbar() {
        var row = [];

        if ((this || _global)._options.buttons.showToday) {
          row.push($("<td>").append($("<a>").attr({
            href: "#",
            tabindex: "-1",
            "data-action": "today",
            "title": (this || _global)._options.tooltips.today
          }).append($("<span>").addClass((this || _global)._options.icons.today))));
        }

        if (!(this || _global)._options.sideBySide && this._hasDate() && this._hasTime()) {
          var title = void 0,
              icon = void 0;

          if ((this || _global)._options.viewMode === "times") {
            title = (this || _global)._options.tooltips.selectDate;
            icon = (this || _global)._options.icons.date;
          } else {
            title = (this || _global)._options.tooltips.selectTime;
            icon = (this || _global)._options.icons.time;
          }

          row.push($("<td>").append($("<a>").attr({
            href: "#",
            tabindex: "-1",
            "data-action": "togglePicker",
            "title": title
          }).append($("<span>").addClass(icon))));
        }

        if ((this || _global)._options.buttons.showClear) {
          row.push($("<td>").append($("<a>").attr({
            href: "#",
            tabindex: "-1",
            "data-action": "clear",
            "title": (this || _global)._options.tooltips.clear
          }).append($("<span>").addClass((this || _global)._options.icons.clear))));
        }

        if ((this || _global)._options.buttons.showClose) {
          row.push($("<td>").append($("<a>").attr({
            href: "#",
            tabindex: "-1",
            "data-action": "close",
            "title": (this || _global)._options.tooltips.close
          }).append($("<span>").addClass((this || _global)._options.icons.close))));
        }

        return row.length === 0 ? "" : $("<table>").addClass("table-condensed").append($("<tbody>").append($("<tr>").append(row)));
      };

      TempusDominusBootstrap4.prototype._getTemplate = function _getTemplate() {
        var template = $("<div>").addClass("bootstrap-datetimepicker-widget dropdown-menu"),
            dateView = $("<div>").addClass("datepicker").append(this._getDatePickerTemplate()),
            timeView = $("<div>").addClass("timepicker").append(this._getTimePickerTemplate()),
            content = $("<ul>").addClass("list-unstyled"),
            toolbar = $("<li>").addClass("picker-switch" + ((this || _global)._options.collapse ? " accordion-toggle" : "")).append(this._getToolbar());

        if ((this || _global)._options.inline) {
          template.removeClass("dropdown-menu");
        }

        if ((this || _global).use24Hours) {
          template.addClass("usetwentyfour");
        }

        if (this._isEnabled("s") && !(this || _global).use24Hours) {
          template.addClass("wider");
        }

        if ((this || _global)._options.sideBySide && this._hasDate() && this._hasTime()) {
          template.addClass("timepicker-sbs");

          if ((this || _global)._options.toolbarPlacement === "top") {
            template.append(toolbar);
          }

          template.append($("<div>").addClass("row").append(dateView.addClass("col-md-6")).append(timeView.addClass("col-md-6")));

          if ((this || _global)._options.toolbarPlacement === "bottom" || (this || _global)._options.toolbarPlacement === "default") {
            template.append(toolbar);
          }

          return template;
        }

        if ((this || _global)._options.toolbarPlacement === "top") {
          content.append(toolbar);
        }

        if (this._hasDate()) {
          content.append($("<li>").addClass((this || _global)._options.collapse && this._hasTime() ? "collapse" : "").addClass((this || _global)._options.collapse && this._hasTime() && (this || _global)._options.viewMode === "times" ? "" : "show").append(dateView));
        }

        if ((this || _global)._options.toolbarPlacement === "default") {
          content.append(toolbar);
        }

        if (this._hasTime()) {
          content.append($("<li>").addClass((this || _global)._options.collapse && this._hasDate() ? "collapse" : "").addClass((this || _global)._options.collapse && this._hasDate() && (this || _global)._options.viewMode === "times" ? "show" : "").append(timeView));
        }

        if ((this || _global)._options.toolbarPlacement === "bottom") {
          content.append(toolbar);
        }

        return template.append(content);
      };

      TempusDominusBootstrap4.prototype._place = function _place(e) {
        var self = e && e.data && e.data.picker || this || _global,
            vertical = self._options.widgetPositioning.vertical,
            horizontal = self._options.widgetPositioning.horizontal,
            parent = void 0;
        var position = (self.component && self.component.length ? self.component : self._element).position(),
            offset = (self.component && self.component.length ? self.component : self._element).offset();

        if (self._options.widgetParent) {
          parent = self._options.widgetParent.append(self.widget);
        } else if (self._element.is("input")) {
          parent = self._element.after(self.widget).parent();
        } else if (self._options.inline) {
          parent = self._element.append(self.widget);
          return;
        } else {
          parent = self._element;

          self._element.children().first().after(self.widget);
        } // Top and bottom logic


        if (vertical === "auto") {
          //noinspection JSValidateTypes
          if (offset.top + self.widget.height() * 1.5 >= $(window).height() + $(window).scrollTop() && self.widget.height() + self._element.outerHeight() < offset.top) {
            vertical = "top";
          } else {
            vertical = "bottom";
          }
        } // Left and right logic


        if (horizontal === "auto") {
          if (parent.width() < offset.left + self.widget.outerWidth() / 2 && offset.left + self.widget.outerWidth() > $(window).width()) {
            horizontal = "right";
          } else {
            horizontal = "left";
          }
        }

        if (vertical === "top") {
          self.widget.addClass("top").removeClass("bottom");
        } else {
          self.widget.addClass("bottom").removeClass("top");
        }

        if (horizontal === "right") {
          self.widget.addClass("float-right");
        } else {
          self.widget.removeClass("float-right");
        } // find the first parent element that has a relative css positioning


        if (parent.css("position") !== "relative") {
          parent = parent.parents().filter(function () {
            return $(this || _global).css("position") === "relative";
          }).first();
        }

        if (parent.length === 0) {
          throw new Error("datetimepicker component should be placed within a relative positioned container");
        }

        self.widget.css({
          top: vertical === "top" ? "auto" : position.top + self._element.outerHeight() + "px",
          bottom: vertical === "top" ? parent.outerHeight() - (parent === self._element ? 0 : position.top) + "px" : "auto",
          left: horizontal === "left" ? (parent === self._element ? 0 : position.left) + "px" : "auto",
          right: horizontal === "left" ? "auto" : parent.outerWidth() - self._element.outerWidth() - (parent === self._element ? 0 : position.left) + "px"
        });
      };

      TempusDominusBootstrap4.prototype._fillDow = function _fillDow() {
        var row = $("<tr>"),
            currentDate = (this || _global)._viewDate.clone().startOf("w").startOf("d");

        if ((this || _global)._options.calendarWeeks === true) {
          row.append($("<th>").addClass("cw").text("#"));
        }

        while (currentDate.isBefore((this || _global)._viewDate.clone().endOf("w"))) {
          row.append($("<th>").addClass("dow").text(currentDate.format("dd")));
          currentDate.add(1, "d");
        }

        (this || _global).widget.find(".datepicker-days thead").append(row);
      };

      TempusDominusBootstrap4.prototype._fillMonths = function _fillMonths() {
        var spans = [],
            monthsShort = (this || _global)._viewDate.clone().startOf("y").startOf("d");

        while (monthsShort.isSame((this || _global)._viewDate, "y")) {
          spans.push($("<span>").attr("data-action", "selectMonth").addClass("month").text(monthsShort.format("MMM")));
          monthsShort.add(1, "M");
        }

        (this || _global).widget.find(".datepicker-months td").empty().append(spans);
      };

      TempusDominusBootstrap4.prototype._updateMonths = function _updateMonths() {
        var monthsView = (this || _global).widget.find(".datepicker-months"),
            monthsViewHeader = monthsView.find("th"),
            months = monthsView.find("tbody").find("span"),
            self = this || _global;

        monthsViewHeader.eq(0).find("span").attr("title", (this || _global)._options.tooltips.prevYear);
        monthsViewHeader.eq(1).attr("title", (this || _global)._options.tooltips.selectYear);
        monthsViewHeader.eq(2).find("span").attr("title", (this || _global)._options.tooltips.nextYear);
        monthsView.find(".disabled").removeClass("disabled");

        if (!this._isValid((this || _global)._viewDate.clone().subtract(1, "y"), "y")) {
          monthsViewHeader.eq(0).addClass("disabled");
        }

        monthsViewHeader.eq(1).text((this || _global)._viewDate.year());

        if (!this._isValid((this || _global)._viewDate.clone().add(1, "y"), "y")) {
          monthsViewHeader.eq(2).addClass("disabled");
        }

        months.removeClass("active");

        if (this._getLastPickedDate().isSame((this || _global)._viewDate, "y") && !(this || _global).unset) {
          months.eq(this._getLastPickedDate().month()).addClass("active");
        }

        months.each(function (index) {
          if (!self._isValid(self._viewDate.clone().month(index), "M")) {
            $(this || _global).addClass("disabled");
          }
        });
      };

      TempusDominusBootstrap4.prototype._getStartEndYear = function _getStartEndYear(factor, year) {
        var step = factor / 10,
            startYear = Math.floor(year / factor) * factor,
            endYear = startYear + step * 9,
            focusValue = Math.floor(year / step) * step;
        return [startYear, endYear, focusValue];
      };

      TempusDominusBootstrap4.prototype._updateYears = function _updateYears() {
        var yearsView = (this || _global).widget.find(".datepicker-years"),
            yearsViewHeader = yearsView.find("th"),
            yearCaps = this._getStartEndYear(10, (this || _global)._viewDate.year()),
            startYear = (this || _global)._viewDate.clone().year(yearCaps[0]),
            endYear = (this || _global)._viewDate.clone().year(yearCaps[1]);

        var html = "";
        yearsViewHeader.eq(0).find("span").attr("title", (this || _global)._options.tooltips.prevDecade);
        yearsViewHeader.eq(1).attr("title", (this || _global)._options.tooltips.selectDecade);
        yearsViewHeader.eq(2).find("span").attr("title", (this || _global)._options.tooltips.nextDecade);
        yearsView.find(".disabled").removeClass("disabled");

        if ((this || _global)._options.minDate && (this || _global)._options.minDate.isAfter(startYear, "y")) {
          yearsViewHeader.eq(0).addClass("disabled");
        }

        yearsViewHeader.eq(1).text(startYear.year() + "-" + endYear.year());

        if ((this || _global)._options.maxDate && (this || _global)._options.maxDate.isBefore(endYear, "y")) {
          yearsViewHeader.eq(2).addClass("disabled");
        }

        html += "<span data-action=\"selectYear\" class=\"year old" + (!this._isValid(startYear, "y") ? " disabled" : "") + "\">" + (startYear.year() - 1) + "</span>";

        while (!startYear.isAfter(endYear, "y")) {
          html += "<span data-action=\"selectYear\" class=\"year" + (startYear.isSame(this._getLastPickedDate(), "y") && !(this || _global).unset ? " active" : "") + (!this._isValid(startYear, "y") ? " disabled" : "") + "\">" + startYear.year() + "</span>";
          startYear.add(1, "y");
        }

        html += "<span data-action=\"selectYear\" class=\"year old" + (!this._isValid(startYear, "y") ? " disabled" : "") + "\">" + startYear.year() + "</span>";
        yearsView.find("td").html(html);
      };

      TempusDominusBootstrap4.prototype._updateDecades = function _updateDecades() {
        var decadesView = (this || _global).widget.find(".datepicker-decades"),
            decadesViewHeader = decadesView.find("th"),
            yearCaps = this._getStartEndYear(100, (this || _global)._viewDate.year()),
            startDecade = (this || _global)._viewDate.clone().year(yearCaps[0]),
            endDecade = (this || _global)._viewDate.clone().year(yearCaps[1]);

        var minDateDecade = false,
            maxDateDecade = false,
            endDecadeYear = void 0,
            html = "";
        decadesViewHeader.eq(0).find("span").attr("title", (this || _global)._options.tooltips.prevCentury);
        decadesViewHeader.eq(2).find("span").attr("title", (this || _global)._options.tooltips.nextCentury);
        decadesView.find(".disabled").removeClass("disabled");

        if (startDecade.year() === 0 || (this || _global)._options.minDate && (this || _global)._options.minDate.isAfter(startDecade, "y")) {
          decadesViewHeader.eq(0).addClass("disabled");
        }

        decadesViewHeader.eq(1).text(startDecade.year() + "-" + endDecade.year());

        if ((this || _global)._options.maxDate && (this || _global)._options.maxDate.isBefore(endDecade, "y")) {
          decadesViewHeader.eq(2).addClass("disabled");
        }

        if (startDecade.year() - 10 < 0) {
          html += "<span>&nbsp;</span>";
        } else {
          html += "<span data-action=\"selectDecade\" class=\"decade old\" data-selection=\"" + (startDecade.year() + 6) + "\">" + (startDecade.year() - 10) + "</span>";
        }

        while (!startDecade.isAfter(endDecade, "y")) {
          endDecadeYear = startDecade.year() + 11;
          minDateDecade = (this || _global)._options.minDate && (this || _global)._options.minDate.isAfter(startDecade, "y") && (this || _global)._options.minDate.year() <= endDecadeYear;
          maxDateDecade = (this || _global)._options.maxDate && (this || _global)._options.maxDate.isAfter(startDecade, "y") && (this || _global)._options.maxDate.year() <= endDecadeYear;
          html += "<span data-action=\"selectDecade\" class=\"decade" + (this._getLastPickedDate().isAfter(startDecade) && this._getLastPickedDate().year() <= endDecadeYear ? " active" : "") + (!this._isValid(startDecade, "y") && !minDateDecade && !maxDateDecade ? " disabled" : "") + "\" data-selection=\"" + (startDecade.year() + 6) + "\">" + startDecade.year() + "</span>";
          startDecade.add(10, "y");
        }

        html += "<span data-action=\"selectDecade\" class=\"decade old\" data-selection=\"" + (startDecade.year() + 6) + "\">" + startDecade.year() + "</span>";
        decadesView.find("td").html(html);
      };

      TempusDominusBootstrap4.prototype._fillDate = function _fillDate() {
        var daysView = (this || _global).widget.find(".datepicker-days"),
            daysViewHeader = daysView.find("th"),
            html = [];

        var currentDate = void 0,
            row = void 0,
            clsName = void 0,
            i = void 0;

        if (!this._hasDate()) {
          return;
        }

        daysViewHeader.eq(0).find("span").attr("title", (this || _global)._options.tooltips.prevMonth);
        daysViewHeader.eq(1).attr("title", (this || _global)._options.tooltips.selectMonth);
        daysViewHeader.eq(2).find("span").attr("title", (this || _global)._options.tooltips.nextMonth);
        daysView.find(".disabled").removeClass("disabled");
        daysViewHeader.eq(1).text((this || _global)._viewDate.format((this || _global)._options.dayViewHeaderFormat));

        if (!this._isValid((this || _global)._viewDate.clone().subtract(1, "M"), "M")) {
          daysViewHeader.eq(0).addClass("disabled");
        }

        if (!this._isValid((this || _global)._viewDate.clone().add(1, "M"), "M")) {
          daysViewHeader.eq(2).addClass("disabled");
        }

        currentDate = (this || _global)._viewDate.clone().startOf("M").startOf("w").startOf("d");

        for (i = 0; i < 42; i++) {
          //always display 42 days (should show 6 weeks)
          if (currentDate.weekday() === 0) {
            row = $("<tr>");

            if ((this || _global)._options.calendarWeeks) {
              row.append("<td class=\"cw\">" + currentDate.week() + "</td>");
            }

            html.push(row);
          }

          clsName = "";

          if (currentDate.isBefore((this || _global)._viewDate, "M")) {
            clsName += " old";
          }

          if (currentDate.isAfter((this || _global)._viewDate, "M")) {
            clsName += " new";
          }

          if ((this || _global)._options.allowMultidate) {
            var index = (this || _global)._datesFormatted.indexOf(currentDate.format("YYYY-MM-DD"));

            if (index !== -1) {
              if (currentDate.isSame((this || _global)._datesFormatted[index], "d") && !(this || _global).unset) {
                clsName += " active";
              }
            }
          } else {
            if (currentDate.isSame(this._getLastPickedDate(), "d") && !(this || _global).unset) {
              clsName += " active";
            }
          }

          if (!this._isValid(currentDate, "d")) {
            clsName += " disabled";
          }

          if (currentDate.isSame(this.getMoment(), "d")) {
            clsName += " today";
          }

          if (currentDate.day() === 0 || currentDate.day() === 6) {
            clsName += " weekend";
          }

          row.append("<td data-action=\"selectDay\" data-day=\"" + currentDate.format("L") + "\" class=\"day" + clsName + "\">" + currentDate.date() + "</td>");
          currentDate.add(1, "d");
        }

        daysView.find("tbody").empty().append(html);

        this._updateMonths();

        this._updateYears();

        this._updateDecades();
      };

      TempusDominusBootstrap4.prototype._fillHours = function _fillHours() {
        var table = (this || _global).widget.find(".timepicker-hours table"),
            currentHour = (this || _global)._viewDate.clone().startOf("d"),
            html = [];

        var row = $("<tr>");

        if ((this || _global)._viewDate.hour() > 11 && !(this || _global).use24Hours) {
          currentHour.hour(12);
        }

        while (currentHour.isSame((this || _global)._viewDate, "d") && ((this || _global).use24Hours || (this || _global)._viewDate.hour() < 12 && currentHour.hour() < 12 || (this || _global)._viewDate.hour() > 11)) {
          if (currentHour.hour() % 4 === 0) {
            row = $("<tr>");
            html.push(row);
          }

          row.append("<td data-action=\"selectHour\" class=\"hour" + (!this._isValid(currentHour, "h") ? " disabled" : "") + "\">" + currentHour.format((this || _global).use24Hours ? "HH" : "hh") + "</td>");
          currentHour.add(1, "h");
        }

        table.empty().append(html);
      };

      TempusDominusBootstrap4.prototype._fillMinutes = function _fillMinutes() {
        var table = (this || _global).widget.find(".timepicker-minutes table"),
            currentMinute = (this || _global)._viewDate.clone().startOf("h"),
            html = [],
            step = (this || _global)._options.stepping === 1 ? 5 : (this || _global)._options.stepping;

        var row = $("<tr>");

        while ((this || _global)._viewDate.isSame(currentMinute, "h")) {
          if (currentMinute.minute() % (step * 4) === 0) {
            row = $("<tr>");
            html.push(row);
          }

          row.append("<td data-action=\"selectMinute\" class=\"minute" + (!this._isValid(currentMinute, "m") ? " disabled" : "") + "\">" + currentMinute.format("mm") + "</td>");
          currentMinute.add(step, "m");
        }

        table.empty().append(html);
      };

      TempusDominusBootstrap4.prototype._fillSeconds = function _fillSeconds() {
        var table = (this || _global).widget.find(".timepicker-seconds table"),
            currentSecond = (this || _global)._viewDate.clone().startOf("m"),
            html = [];

        var row = $("<tr>");

        while ((this || _global)._viewDate.isSame(currentSecond, "m")) {
          if (currentSecond.second() % 20 === 0) {
            row = $("<tr>");
            html.push(row);
          }

          row.append("<td data-action=\"selectSecond\" class=\"second" + (!this._isValid(currentSecond, "s") ? " disabled" : "") + "\">" + currentSecond.format("ss") + "</td>");
          currentSecond.add(5, "s");
        }

        table.empty().append(html);
      };

      TempusDominusBootstrap4.prototype._fillTime = function _fillTime() {
        var toggle = void 0,
            newDate = void 0;

        var timeComponents = (this || _global).widget.find(".timepicker span[data-time-component]");

        if (!(this || _global).use24Hours) {
          toggle = (this || _global).widget.find(".timepicker [data-action=togglePeriod]");
          newDate = this._getLastPickedDate().clone().add(this._getLastPickedDate().hours() >= 12 ? -12 : 12, "h");
          toggle.text(this._getLastPickedDate().format("A"));

          if (this._isValid(newDate, "h")) {
            toggle.removeClass("disabled");
          } else {
            toggle.addClass("disabled");
          }
        }

        timeComponents.filter("[data-time-component=hours]").text(this._getLastPickedDate().format("" + ((this || _global).use24Hours ? "HH" : "hh")));
        timeComponents.filter("[data-time-component=minutes]").text(this._getLastPickedDate().format("mm"));
        timeComponents.filter("[data-time-component=seconds]").text(this._getLastPickedDate().format("ss"));

        this._fillHours();

        this._fillMinutes();

        this._fillSeconds();
      };

      TempusDominusBootstrap4.prototype._doAction = function _doAction(e, action) {
        var lastPicked = this._getLastPickedDate();

        if ($(e.currentTarget).is(".disabled")) {
          return false;
        }

        action = action || $(e.currentTarget).data("action");

        switch (action) {
          case "next":
            {
              var navFnc = DateTimePicker.DatePickerModes[(this || _global).currentViewMode].NAV_FUNCTION;

              (this || _global)._viewDate.add(DateTimePicker.DatePickerModes[(this || _global).currentViewMode].NAV_STEP, navFnc);

              this._fillDate();

              this._viewUpdate(navFnc);

              break;
            }

          case "previous":
            {
              var _navFnc = DateTimePicker.DatePickerModes[(this || _global).currentViewMode].NAV_FUNCTION;

              (this || _global)._viewDate.subtract(DateTimePicker.DatePickerModes[(this || _global).currentViewMode].NAV_STEP, _navFnc);

              this._fillDate();

              this._viewUpdate(_navFnc);

              break;
            }

          case "pickerSwitch":
            this._showMode(1);

            break;

          case "selectMonth":
            {
              var month = $(e.target).closest("tbody").find("span").index($(e.target));

              (this || _global)._viewDate.month(month);

              if ((this || _global).currentViewMode === (this || _global).MinViewModeNumber) {
                this._setValue(lastPicked.clone().year((this || _global)._viewDate.year()).month((this || _global)._viewDate.month()), this._getLastPickedDateIndex());

                if (!(this || _global)._options.inline) {
                  this.hide();
                }
              } else {
                this._showMode(-1);

                this._fillDate();
              }

              this._viewUpdate("M");

              break;
            }

          case "selectYear":
            {
              var year = parseInt($(e.target).text(), 10) || 0;

              (this || _global)._viewDate.year(year);

              if ((this || _global).currentViewMode === (this || _global).MinViewModeNumber) {
                this._setValue(lastPicked.clone().year((this || _global)._viewDate.year()), this._getLastPickedDateIndex());

                if (!(this || _global)._options.inline) {
                  this.hide();
                }
              } else {
                this._showMode(-1);

                this._fillDate();
              }

              this._viewUpdate("YYYY");

              break;
            }

          case "selectDecade":
            {
              var _year = parseInt($(e.target).data("selection"), 10) || 0;

              (this || _global)._viewDate.year(_year);

              if ((this || _global).currentViewMode === (this || _global).MinViewModeNumber) {
                this._setValue(lastPicked.clone().year((this || _global)._viewDate.year()), this._getLastPickedDateIndex());

                if (!(this || _global)._options.inline) {
                  this.hide();
                }
              } else {
                this._showMode(-1);

                this._fillDate();
              }

              this._viewUpdate("YYYY");

              break;
            }

          case "selectDay":
            {
              var day = (this || _global)._viewDate.clone();

              if ($(e.target).is(".old")) {
                day.subtract(1, "M");
              }

              if ($(e.target).is(".new")) {
                day.add(1, "M");
              }

              var selectDate = day.date(parseInt($(e.target).text(), 10)),
                  index = 0;

              if ((this || _global)._options.allowMultidate) {
                index = (this || _global)._datesFormatted.indexOf(selectDate.format("YYYY-MM-DD"));

                if (index !== -1) {
                  this._setValue(null, index); //deselect multidate

                } else {
                  this._setValue(selectDate, this._getLastPickedDateIndex() + 1);
                }
              } else {
                this._setValue(selectDate, this._getLastPickedDateIndex());
              }

              if (!this._hasTime() && !(this || _global)._options.keepOpen && !(this || _global)._options.inline && !(this || _global)._options.allowMultidate) {
                this.hide();
              }

              break;
            }

          case "incrementHours":
            {
              var newDate = lastPicked.clone().add(1, "h");

              if (this._isValid(newDate, "h")) {
                this._setValue(newDate, this._getLastPickedDateIndex());
              }

              break;
            }

          case "incrementMinutes":
            {
              var _newDate = lastPicked.clone().add((this || _global)._options.stepping, "m");

              if (this._isValid(_newDate, "m")) {
                this._setValue(_newDate, this._getLastPickedDateIndex());
              }

              break;
            }

          case "incrementSeconds":
            {
              var _newDate2 = lastPicked.clone().add(1, "s");

              if (this._isValid(_newDate2, "s")) {
                this._setValue(_newDate2, this._getLastPickedDateIndex());
              }

              break;
            }

          case "decrementHours":
            {
              var _newDate3 = lastPicked.clone().subtract(1, "h");

              if (this._isValid(_newDate3, "h")) {
                this._setValue(_newDate3, this._getLastPickedDateIndex());
              }

              break;
            }

          case "decrementMinutes":
            {
              var _newDate4 = lastPicked.clone().subtract((this || _global)._options.stepping, "m");

              if (this._isValid(_newDate4, "m")) {
                this._setValue(_newDate4, this._getLastPickedDateIndex());
              }

              break;
            }

          case "decrementSeconds":
            {
              var _newDate5 = lastPicked.clone().subtract(1, "s");

              if (this._isValid(_newDate5, "s")) {
                this._setValue(_newDate5, this._getLastPickedDateIndex());
              }

              break;
            }

          case "togglePeriod":
            {
              this._setValue(lastPicked.clone().add(lastPicked.hours() >= 12 ? -12 : 12, "h"), this._getLastPickedDateIndex());

              break;
            }

          case "togglePicker":
            {
              var $this = $(e.target),
                  $link = $this.closest("a"),
                  $parent = $this.closest("ul"),
                  expanded = $parent.find(".show"),
                  closed = $parent.find(".collapse:not(.show)"),
                  $span = $this.is("span") ? $this : $this.find("span");
              var collapseData = void 0;

              if (expanded && expanded.length) {
                collapseData = expanded.data("collapse");

                if (collapseData && collapseData.transitioning) {
                  return true;
                }

                if (expanded.collapse) {
                  // if collapse plugin is available through bootstrap.js then use it
                  expanded.collapse("hide");
                  closed.collapse("show");
                } else {
                  // otherwise just toggle in class on the two views
                  expanded.removeClass("show");
                  closed.addClass("show");
                }

                $span.toggleClass((this || _global)._options.icons.time + " " + (this || _global)._options.icons.date);

                if ($span.hasClass((this || _global)._options.icons.date)) {
                  $link.attr("title", (this || _global)._options.tooltips.selectDate);
                } else {
                  $link.attr("title", (this || _global)._options.tooltips.selectTime);
                }
              }
            }
            break;

          case "showPicker":
            (this || _global).widget.find(".timepicker > div:not(.timepicker-picker)").hide();

            (this || _global).widget.find(".timepicker .timepicker-picker").show();

            break;

          case "showHours":
            (this || _global).widget.find(".timepicker .timepicker-picker").hide();

            (this || _global).widget.find(".timepicker .timepicker-hours").show();

            break;

          case "showMinutes":
            (this || _global).widget.find(".timepicker .timepicker-picker").hide();

            (this || _global).widget.find(".timepicker .timepicker-minutes").show();

            break;

          case "showSeconds":
            (this || _global).widget.find(".timepicker .timepicker-picker").hide();

            (this || _global).widget.find(".timepicker .timepicker-seconds").show();

            break;

          case "selectHour":
            {
              var hour = parseInt($(e.target).text(), 10);

              if (!(this || _global).use24Hours) {
                if (lastPicked.hours() >= 12) {
                  if (hour !== 12) {
                    hour += 12;
                  }
                } else {
                  if (hour === 12) {
                    hour = 0;
                  }
                }
              }

              this._setValue(lastPicked.clone().hours(hour), this._getLastPickedDateIndex());

              if (!this._isEnabled("a") && !this._isEnabled("m") && !(this || _global)._options.keepOpen && !(this || _global)._options.inline) {
                this.hide();
              } else {
                this._doAction(e, "showPicker");
              }

              break;
            }

          case "selectMinute":
            this._setValue(lastPicked.clone().minutes(parseInt($(e.target).text(), 10)), this._getLastPickedDateIndex());

            if (!this._isEnabled("a") && !this._isEnabled("s") && !(this || _global)._options.keepOpen && !(this || _global)._options.inline) {
              this.hide();
            } else {
              this._doAction(e, "showPicker");
            }

            break;

          case "selectSecond":
            this._setValue(lastPicked.clone().seconds(parseInt($(e.target).text(), 10)), this._getLastPickedDateIndex());

            if (!this._isEnabled("a") && !(this || _global)._options.keepOpen && !(this || _global)._options.inline) {
              this.hide();
            } else {
              this._doAction(e, "showPicker");
            }

            break;

          case "clear":
            this.clear();
            break;

          case "close":
            this.hide();
            break;

          case "today":
            {
              var todaysDate = this.getMoment();

              if (this._isValid(todaysDate, "d")) {
                this._setValue(todaysDate, this._getLastPickedDateIndex());
              }

              break;
            }
        }

        return false;
      }; //public


      TempusDominusBootstrap4.prototype.hide = function hide() {
        var transitioning = false;

        if (!(this || _global).widget) {
          return;
        } // Ignore event if in the middle of a picker transition


        (this || _global).widget.find(".collapse").each(function () {
          var collapseData = $(this || _global).data("collapse");

          if (collapseData && collapseData.transitioning) {
            transitioning = true;
            return false;
          }

          return true;
        });

        if (transitioning) {
          return;
        }

        if ((this || _global).component && (this || _global).component.hasClass("btn")) {
          (this || _global).component.toggleClass("active");
        }

        (this || _global).widget.hide();

        $(window).off("resize", this._place());

        (this || _global).widget.off("click", "[data-action]");

        (this || _global).widget.off("mousedown", false);

        (this || _global).widget.remove();

        (this || _global).widget = false;

        this._notifyEvent({
          type: DateTimePicker.Event.HIDE,
          date: this._getLastPickedDate().clone()
        });

        if ((this || _global).input !== undefined) {
          (this || _global).input.blur();
        }

        (this || _global)._viewDate = this._getLastPickedDate().clone();
      };

      TempusDominusBootstrap4.prototype.show = function show() {
        var currentMoment = void 0;
        var useCurrentGranularity = {
          "year": function year(m) {
            return m.month(0).date(1).hours(0).seconds(0).minutes(0);
          },
          "month": function month(m) {
            return m.date(1).hours(0).seconds(0).minutes(0);
          },
          "day": function day(m) {
            return m.hours(0).seconds(0).minutes(0);
          },
          "hour": function hour(m) {
            return m.seconds(0).minutes(0);
          },
          "minute": function minute(m) {
            return m.seconds(0);
          }
        };

        if ((this || _global).input !== undefined) {
          if ((this || _global).input.prop("disabled") || !(this || _global)._options.ignoreReadonly && (this || _global).input.prop("readonly") || (this || _global).widget) {
            return;
          }

          if ((this || _global).input.val() !== undefined && (this || _global).input.val().trim().length !== 0) {
            this._setValue(this._parseInputDate((this || _global).input.val().trim()), 0);
          } else if ((this || _global).unset && (this || _global)._options.useCurrent) {
            currentMoment = this.getMoment();

            if (typeof (this || _global)._options.useCurrent === "string") {
              currentMoment = useCurrentGranularity[(this || _global)._options.useCurrent](currentMoment);
            }

            this._setValue(currentMoment, 0);
          }
        } else if ((this || _global).unset && (this || _global)._options.useCurrent) {
          currentMoment = this.getMoment();

          if (typeof (this || _global)._options.useCurrent === "string") {
            currentMoment = useCurrentGranularity[(this || _global)._options.useCurrent](currentMoment);
          }

          this._setValue(currentMoment, 0);
        }

        (this || _global).widget = this._getTemplate();

        this._fillDow();

        this._fillMonths();

        (this || _global).widget.find(".timepicker-hours").hide();

        (this || _global).widget.find(".timepicker-minutes").hide();

        (this || _global).widget.find(".timepicker-seconds").hide();

        this._update();

        this._showMode();

        $(window).on("resize", {
          picker: this || _global
        }, (this || _global)._place);

        (this || _global).widget.on("click", "[data-action]", $.proxy((this || _global)._doAction, this || _global)); // this handles clicks on the widget


        (this || _global).widget.on("mousedown", false);

        if ((this || _global).component && (this || _global).component.hasClass("btn")) {
          (this || _global).component.toggleClass("active");
        }

        this._place();

        (this || _global).widget.show();

        if ((this || _global).input !== undefined && (this || _global)._options.focusOnShow && !(this || _global).input.is(":focus")) {
          (this || _global).input.focus();
        }

        this._notifyEvent({
          type: DateTimePicker.Event.SHOW
        });
      };

      TempusDominusBootstrap4.prototype.destroy = function destroy() {
        this.hide(); //todo doc off?

        (this || _global)._element.removeData(DateTimePicker.DATA_KEY);

        (this || _global)._element.removeData("date");
      };

      TempusDominusBootstrap4.prototype.disable = function disable() {
        this.hide();

        if ((this || _global).component && (this || _global).component.hasClass("btn")) {
          (this || _global).component.addClass("disabled");
        }

        if ((this || _global).input !== undefined) {
          (this || _global).input.prop("disabled", true); //todo disable this/comp if input is null

        }
      };

      TempusDominusBootstrap4.prototype.enable = function enable() {
        if ((this || _global).component && (this || _global).component.hasClass("btn")) {
          (this || _global).component.removeClass("disabled");
        }

        if ((this || _global).input !== undefined) {
          (this || _global).input.prop("disabled", false); //todo enable comp/this if input is null

        }
      };

      TempusDominusBootstrap4.prototype.toolbarPlacement = function toolbarPlacement(_toolbarPlacement) {
        if (arguments.length === 0) {
          return (this || _global)._options.toolbarPlacement;
        }

        if (typeof _toolbarPlacement !== "string") {
          throw new TypeError("toolbarPlacement() expects a string parameter");
        }

        if (toolbarPlacements.indexOf(_toolbarPlacement) === -1) {
          throw new TypeError("toolbarPlacement() parameter must be one of (" + toolbarPlacements.join(", ") + ") value");
        }

        (this || _global)._options.toolbarPlacement = _toolbarPlacement;

        if ((this || _global).widget) {
          this.hide();
          this.show();
        }
      };

      TempusDominusBootstrap4.prototype.widgetPositioning = function widgetPositioning(_widgetPositioning) {
        if (arguments.length === 0) {
          return $.extend({}, (this || _global)._options.widgetPositioning);
        }

        if ({}.toString.call(_widgetPositioning) !== "[object Object]") {
          throw new TypeError("widgetPositioning() expects an object variable");
        }

        if (_widgetPositioning.horizontal) {
          if (typeof _widgetPositioning.horizontal !== "string") {
            throw new TypeError("widgetPositioning() horizontal variable must be a string");
          }

          _widgetPositioning.horizontal = _widgetPositioning.horizontal.toLowerCase();

          if (horizontalModes.indexOf(_widgetPositioning.horizontal) === -1) {
            throw new TypeError("widgetPositioning() expects horizontal parameter to be one of (" + horizontalModes.join(", ") + ")");
          }

          (this || _global)._options.widgetPositioning.horizontal = _widgetPositioning.horizontal;
        }

        if (_widgetPositioning.vertical) {
          if (typeof _widgetPositioning.vertical !== "string") {
            throw new TypeError("widgetPositioning() vertical variable must be a string");
          }

          _widgetPositioning.vertical = _widgetPositioning.vertical.toLowerCase();

          if (verticalModes.indexOf(_widgetPositioning.vertical) === -1) {
            throw new TypeError("widgetPositioning() expects vertical parameter to be one of (" + verticalModes.join(", ") + ")");
          }

          (this || _global)._options.widgetPositioning.vertical = _widgetPositioning.vertical;
        }

        this._update();
      };

      TempusDominusBootstrap4.prototype.widgetParent = function widgetParent(_widgetParent) {
        if (arguments.length === 0) {
          return (this || _global)._options.widgetParent;
        }

        if (typeof _widgetParent === "string") {
          _widgetParent = $(_widgetParent);
        }

        if (_widgetParent !== null && typeof _widgetParent !== "string" && !(_widgetParent instanceof $)) {
          throw new TypeError("widgetParent() expects a string or a jQuery object parameter");
        }

        (this || _global)._options.widgetParent = _widgetParent;

        if ((this || _global).widget) {
          this.hide();
          this.show();
        }
      }; //static


      TempusDominusBootstrap4._jQueryHandleThis = function _jQueryHandleThis(me, option, argument) {
        var data = $(me).data(DateTimePicker.DATA_KEY);

        if ((typeof option === "undefined" ? "undefined" : _typeof(option)) === "object") {
          $.extend({}, DateTimePicker.Default, option);
        }

        if (!data) {
          data = new TempusDominusBootstrap4($(me), option);
          $(me).data(DateTimePicker.DATA_KEY, data);
        }

        if (typeof option === "string") {
          if (data[option] === undefined) {
            throw new Error("No method named \"" + option + "\"");
          }

          if (argument === undefined) {
            return data[option]();
          } else {
            return data[option](argument);
          }
        }
      };

      TempusDominusBootstrap4._jQueryInterface = function _jQueryInterface(option, argument) {
        if ((this || _global).length === 1) {
          return TempusDominusBootstrap4._jQueryHandleThis((this || _global)[0], option, argument);
        }

        return this.each(function () {
          TempusDominusBootstrap4._jQueryHandleThis(this || _global, option, argument);
        });
      };

      return TempusDominusBootstrap4;
    }(DateTimePicker);
    /**
    * ------------------------------------------------------------------------
    * jQuery
    * ------------------------------------------------------------------------
    */


    $(document).on(DateTimePicker.Event.CLICK_DATA_API, DateTimePicker.Selector.DATA_TOGGLE, function () {
      var $target = getSelectorFromElement($(this || _global));

      if ($target.length === 0) {
        return;
      }

      TempusDominusBootstrap4._jQueryInterface.call($target, "toggle");
    }).on(DateTimePicker.Event.CHANGE, "." + DateTimePicker.ClassName.INPUT, function (event) {
      var $target = getSelectorFromElement($(this || _global));

      if ($target.length === 0) {
        return;
      }

      TempusDominusBootstrap4._jQueryInterface.call($target, "_change", event);
    }).on(DateTimePicker.Event.BLUR, "." + DateTimePicker.ClassName.INPUT, function (event) {
      var $target = getSelectorFromElement($(this || _global)),
          config = $target.data(DateTimePicker.DATA_KEY);

      if ($target.length === 0) {
        return;
      }

      if (config._options.debug || window.debug) {
        return;
      }

      TempusDominusBootstrap4._jQueryInterface.call($target, "hide", event);
    }).on(DateTimePicker.Event.KEYDOWN, "." + DateTimePicker.ClassName.INPUT, function (event) {
      var $target = getSelectorFromElement($(this || _global));

      if ($target.length === 0) {
        return;
      }

      TempusDominusBootstrap4._jQueryInterface.call($target, "_keydown", event);
    }).on(DateTimePicker.Event.KEYUP, "." + DateTimePicker.ClassName.INPUT, function (event) {
      var $target = getSelectorFromElement($(this || _global));

      if ($target.length === 0) {
        return;
      }

      TempusDominusBootstrap4._jQueryInterface.call($target, "_keyup", event);
    }).on(DateTimePicker.Event.FOCUS, "." + DateTimePicker.ClassName.INPUT, function (event) {
      var $target = getSelectorFromElement($(this || _global)),
          config = $target.data(DateTimePicker.DATA_KEY);

      if ($target.length === 0) {
        return;
      }

      if (!config._options.allowInputToggle) {
        return;
      }

      TempusDominusBootstrap4._jQueryInterface.call($target, "show", event);
    });
    $.fn[DateTimePicker.NAME] = TempusDominusBootstrap4._jQueryInterface;
    $.fn[DateTimePicker.NAME].Constructor = TempusDominusBootstrap4;

    $.fn[DateTimePicker.NAME].noConflict = function () {
      $.fn[DateTimePicker.NAME] = JQUERY_NO_CONFLICT;
      return TempusDominusBootstrap4._jQueryInterface;
    };

    return TempusDominusBootstrap4;
  }(jQuery);
}();
export default {};